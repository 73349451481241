import React from 'react';
import styled from 'styled-components';
import { Close, Column, Place, Row } from '@lib/nvstr-common-ui.es';
import { colorPalette } from '@lib/nvstr-utils.es';

const Message = styled.div`
  @keyframes fadeInUp {
    from {
      transform: translate3d(0, 40px, 0);
    }

    to {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeInUp {
    from {
      transform: translate3d(0, 40px, 0);
    }

    to {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }

  opacity: 0;
  transition: opacity ease-out 300ms;

  background-color: ${colorPalette.secondary.purple};
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  padding: 28px 20px 28px 28px;
  border-radius: 8px;
  width: 450px;
  max-width: 92%;
  display: inline-block;

  .text {
    color: ${colorPalette.primary.oak};
  }

  &.fade-in {
    animation-duration: 600ms;
    animation-fill-mode: both;
    -webkit-animation-duration: 600ms;
    -webkit-animation-fill-mode: both;

    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
  }
`;

const DismissButtonWrapper = styled.div`
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color ease-out 150ms;

  &:hover {
    background-color: ${colorPalette.primary.oak};
    g {
      fill: ${colorPalette.secondary.purple};
    }
  }
`;

const DismissButton = ({ width, onClick }) => {
  return (
    <DismissButtonWrapper onClick={onClick}>
      <Close isDarkMode width={width} />
    </DismissButtonWrapper>
  );
};

const ApplicationMessage = ({ message }) => {
  const [isDismissed, setIsDismissed] = React.useState(false);
  const [fadeIn, setFadeIn] = React.useState(false);

  React.useEffect(() => {
    const animationDelay = 300;
    setTimeout(() => {
      setFadeIn(true);
    }, animationDelay);
  }, []);

  if (isDismissed) {
    return null;
  }

  return (
    <Message className={fadeIn ? 'fade-in' : ''}>
      <Row vAlign="center" style={{ justifyContent: 'space-between' }}>
        <Place padding="0 20px 0 0">
          <span className="text">{message}</span>
        </Place>
        <Place>
          <DismissButton width={17} onClick={() => setIsDismissed(true)} />
        </Place>
      </Row>
    </Message>
  );
};

const SessionMessageContainer = styled.div`
  position: fixed;
  height: 100px;
  top: 40px;
  left: 0;
  right: 0;
  z-index: 20;
`;

export const usePublicPageSessionMessage = () => {
  const [sessionMessage, setSessionMessage] = React.useState(null);

  const SessionMessageComponent = () => {
    return (
      <SessionMessageContainer>
        <Column hAlign="center">
          <ApplicationMessage message={sessionMessage} />
        </Column>
      </SessionMessageContainer>
    );
  };

  return {
    SessionMessageComponent: sessionMessage ? <SessionMessageComponent /> : null,
    setSessionMessage,
  };
};
