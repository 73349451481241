import React from 'react';
import styled from 'styled-components';
import { Layout } from '@components/Layout';
import { Bold } from '@components/Text';
import { colorPalette } from '@lib/nvstr-utils.es';
import {
  ConversationsFeature,
  NewsFeature,
  OptimizePortfolioFeature,
  Pricing,
  CashPromo,
  PressMentions,
} from '@components/Content';
import { PATHS } from '@constants/paths';
import { TrackingEvents } from '../tracking';
import { parseQueryString } from 'utils/usefulFuncs';
import HeroStatic from '@components/Content/HeroStatic';
import AltSplash from '../pages/_altSplash';
import LearnAndEarnOverview from '@components/Content/LearnAndEarnOverview';
import { usePublicPageSessionMessage } from '@utils/hooks/usePublicPageSessionMessage';
import { IS_WHITE_LABELED } from '../appConfig';

const Wrapper = styled.div`
  background: ${colorPalette.secondary.white};
  overflow: hidden;

  .section-container {
    padding-left: 100px;
    padding-right: 100px;
  }

  @media (max-width: 1200px) {
    .section-container {
      padding-left: 40px;
      padding-right: 40px;
    }
  }

  @media (max-width: 999px) {
    .section-container {
      padding-left: 10%;
      padding-right: 10%;
    }
  }

  @media (max-width: 600px) {
    .section-container {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  @media (min-width: 650px) and (max-width: 999px) {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 1.4em;
      line-height: 110%;
    }
  }

  @media (max-width: 400px) {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 0.8em;
      line-height: 120%;
    }
  }
`;

const SessionMessageContainer = styled.div`
  position: fixed;
  height: 100px;
  top: 135px;
  left: 0;
  right: 0;
  z-index: 20;
`;

const Home = () => {
  const { SessionMessageComponent, setSessionMessage } = usePublicPageSessionMessage();

  React.useEffect(() => {
    const properties = {};
    TrackingEvents.splash.viewHomepage.send(properties);
  }, []);

  React.useEffect(() => {
    const handleAuthParams = (query) => {
      if (query.loginRequired || query.sessionExpired) {
        const message = query.loginRequired ? (
          <>
            Please{' '}
            <a href={PATHS.SIGN_IN} style={{ textDecoration: 'none' }}>
              <Bold>log in</Bold>
            </a>{' '}
            to continue
          </>
        ) : (
          <>
            You were logged out due to inactivity. Please{' '}
            <a href={PATHS.SIGN_IN} style={{ textDecoration: 'none' }}>
              <Bold>log in</Bold>
            </a>{' '}
            to continue.
          </>
        );
        setSessionMessage(message);
      }
    };
    const query = parseQueryString(window.location.search);
    handleAuthParams(query);
  }, []);

  if (IS_WHITE_LABELED) {
    return <AltSplash SessionMessageComponent={SessionMessageComponent} />;
  }

  return (
    <Layout section="home" headerBgColor={'#f7f5f5'}>
      <Wrapper>
        {SessionMessageComponent}
        <div style={{ position: 'relative', zIndex: 3 }}>
          <div style={{ background: 'linear-gradient(180deg, #f7f5f5 0%, #f7f5f5 86%, #f7f5f5 100%)' }}>
            <HeroStatic imageTitle="CommunityThoughtLeaderStill" />
            <div style={{ position: 'relative', zIndex: 1 }}>
              <PressMentions />
            </div>
            <LearnAndEarnOverview />
          </div>
          <ConversationsFeature />
          <NewsFeature />
          <OptimizePortfolioFeature />
          <CashPromo />
          <Pricing />
        </div>
      </Wrapper>
    </Layout>
  );
};

export default Home;
